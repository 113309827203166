<template>
  <div id="contents" class="contents product_add_page">
    <div class="content_head">
      <!-- s: 페이지 타이틀 -->
      <h2 class="page_ttl" v-if="pageMode !== 'modify'">상품 등록</h2>
      <h2 class="page_ttl" v-if="pageMode === 'modify'">상품 수정</h2>
      <!-- e: 페이지 타이틀 -->
    </div>

    <!-- s: content_body -->
    <div class="content_body">
      <!-- s: input_sec -->
      <div class="board_sec">
        <div class="tbl_wrap list form">
          <table class="tbl">
            <caption></caption>
            <colgroup>
              <col width="200"/>
              <col/>
              <col/>
              <col width="auto"/>
            </colgroup>
            <tbody>
            <!-- Dev 수정모드일 경우에만 등록일시/수정일시 노출 -->
            <tr v-if="pageMode === 'modify'">
              <th>등록 일시</th>
              <td>
                <p>2021.07.05 12:33:56</p>
              </td>
              <th >수정 일시</th>
              <td>
                <p>2021.07.05 12:33:56</p>
              </td>
            </tr>
            <tr>
              <th class="must_txt">상품명</th>
              <td colspan="3">
                <!-- Dev: 오류 텍스트 노출 시 input_area에 is_error 클래스 추가-->
                <div class="input_area inline">
                  <label></label>
                  <div class="input_box">
                    <input type="text">
                  </div>
                  <small class="error_txt" style="display: none">상품명을 입력 해 주세요.</small>
                </div>
              </td>
            </tr>
            <tr>
              <th class="must_txt">상품 이미지</th>
              <td colspan="3">
                <div class="bg_box">
                  <ul>
                    <li>
                      <span>이미지 파일형식 PNG </span>
                      <span>용량 1MB 이하</span>
                      <span>해상도 추후결정</span>
                    </li>
                  </ul>
                </div>
                <div class="upload_area clearFix">
                  <!-- Dev: 파일 첨부 후 is_error 클래스 삭제, video 클래스 추가 -->
                  <div class="upload_box flL is_error">

                    <!-- Dev s: 등록된 파일 없을 경우 -->
                    <div class="upload_btn" role="button">
                      <div class="txt_area">
                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                      </div>
                      <div class="btn_area">
                        <button type="button" class="btn basic w_s h_s">파일추가</button>
                      </div>
                      <input type="file" accept="image/png">
                    </div>
                    <small class="error_txt">파일을 선택 해 주세요.</small>
                    <!-- Dev e: 등록된 파일 없을 경우 -->

                    <!-- Dev s: 등록된 파일 있을 경우 -->
                    <div class="upload_btn has_file" role="button" style="display: none">
                      <figure v-if="isImage(file)" class="uploaded_img">
                        <img :src="getFile(file)" alt="">
                      </figure>
                      <input type="file"
                             accept="image/png"
                      >
                      <div class="btn_area">
                        <!-- Dev : 다운로드 버튼은 수정 페이지에서만 보이게 해주세요 -->
                        <button v-if="pageMode === 'modify'" type="button" class="btn w_s h_s basic">
                          다운로드
                        </button>
                        <button type="button" class="btn w_s h_s basic">
                          삭제
                        </button>
                      </div>
                      <p class="filename">파일명.mp4</p>
                    </div>
                    <!-- Dev e: 등록된 파일 있을 경우 -->
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="must_txt">상품 설명</th>
              <td colspan="3">
                <div class="bg_box">
                  <ul>
                    <li>
                      <span>이미지 파일형식 PNG, JPG</span>
                      <span>용량 1MB 이하</span>
                      <span>해상도 추후결정</span>
                    </li>
                  </ul>
                </div>
                <div class="upload_area clearFix">
                  <!-- Dev: 파일 첨부 후 is_error 클래스 삭제, video 클래스 추가 -->
                  <div class="upload_box flL is_error">

                    <!-- Dev s: 등록된 파일 없을 경우 -->
                    <div class="upload_btn" role="button">
                      <div class="txt_area">
                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                      </div>
                      <div class="btn_area">
                        <button type="button" class="btn basic w_s h_s">파일추가</button>
                      </div>
                      <input type="file" accept="image/png, image/jpg">
                    </div>
                    <small class="error_txt">파일을 선택 해 주세요.</small>
                    <!-- Dev e: 등록된 파일 없을 경우 -->

                    <!-- Dev s: 등록된 파일 있을 경우 -->
                    <div class="upload_btn has_file" role="button" style="display: none">
                      <figure v-if="isImage(file)" class="uploaded_img">
                        <img :src="getFile(file)" alt="">
                      </figure>
                      <input type="file"
                             accept="image/png, image/jpg"
                      >
                      <div class="btn_area">
                        <!-- Dev : 다운로드 버튼은 수정 페이지에서만 보이게 해주세요 -->
                        <button v-if="pageMode === 'modify'" type="button" class="btn w_s h_s basic">
                          다운로드
                        </button>
                        <button type="button" class="btn w_s h_s basic">
                          삭제
                        </button>
                      </div>
                      <p class="filename">파일명.mp4</p>
                    </div>
                    <!-- Dev e: 등록된 파일 있을 경우 -->
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="must_txt">QR 이미지</th>
              <td colspan="3">
                <div class="bg_box">
                  <ul>
                    <li>
                      <span>이미지 파일형식 PNG, JPG</span>
                      <span>용량 1MB 이하</span>
                      <span>해상도 추후결정</span>
                    </li>
                  </ul>
                </div>
                <div class="upload_area clearFix">
                  <!-- Dev: 파일 첨부 후 is_error 클래스 삭제, video 클래스 추가 -->
                  <div class="upload_box flL is_error">

                    <!-- Dev s: 등록된 파일 없을 경우 -->
                    <div class="upload_btn" role="button">
                      <div class="txt_area">
                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                      </div>
                      <div class="btn_area">
                        <button type="button" class="btn basic w_s h_s">파일추가</button>
                      </div>
                      <input type="file" accept="image/png, image/jpg">
                    </div>
                    <small class="error_txt">파일을 선택 해 주세요.</small>
                    <!-- Dev e: 등록된 파일 없을 경우 -->

                    <!-- Dev s: 등록된 파일 있을 경우 -->
                    <div class="upload_btn has_file" role="button" style="display: none">
                      <figure v-if="isImage(file)" class="uploaded_img">
                        <img :src="getFile(file)" alt="">
                      </figure>
                      <input type="file"
                             accept="image/png, image/jpg"
                      >
                      <div class="btn_area">
                        <!-- Dev : 다운로드 버튼은 수정 페이지에서만 보이게 해주세요 -->
                        <button v-if="pageMode === 'modify'" type="button" class="btn w_s h_s basic">
                          다운로드
                        </button>
                        <button type="button" class="btn w_s h_s basic">
                          삭제
                        </button>
                      </div>
                      <p class="filename">파일명.mp4</p>
                    </div>
                    <!-- Dev e: 등록된 파일 있을 경우 -->
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <!-- e: board_sec -->
    </div>
    <!-- e: content_body -->

    <!-- s: content_foot -->
    <div class="content_foot">
      <div class="btn_group center">
        <button class="btn basic w_l h_m" @click="openModal()">미리보기</button>
        <button class="btn basic w_l h_m" @click="redirect('/subMenu/products')">취소</button>
        <button v-if="pageMode === 'modify'" class="btn basic w_l h_m" @click="removeProduct()">삭제</button>
        <button v-if="pageMode === 'modify'" class="btn strong w_l h_m" @click="modifyProduct()">수정</button>
        <button v-else class="btn strong w_l h_m">등록</button>
      </div>
    </div>
    <!-- e: content_foot -->
  </div>

  <!-- s: 미리보기 모달 -->
  <div id="modal" class="modal_wrap preview main_video landscape" v-show="productPreviewModal">
    <div class="modal type2">
      <button type="button" class="close_modal" @click="closeModal()"></button>
      <div class="modal_body">
        <div class="display_wrap">
          <div class="display_frame">
            <!-- Dev: 등록된 상품 설명 이미지 info_area에 background-image로 적용-->
            <div class="info_area" style="background-image: url('')"></div>
            <div class="img_area card">
              <!-- Dev: 등록된 상품 이미지 img_box에 background-image로 적용-->
              <div class="img_box" style="background-image: url('')"></div>
              <div class="qr_area">
                <!-- Dev: 등록된 QR 이미지 qr_box에 background-image로 적용-->
                <div class="qr_box" style="background-image: url('')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- e: 미리보기 모달 -->

  <!-- s: 삭제 확인 Dialog -->
  <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false" v-on:yesDialog="confirmRemove()">
    <template #body>
      <h3>삭제 확인</h3>
      <p>선택한 상품를 삭제하시겠습니까? <br/>변경된 내용은 "발행하기"를 진행해야 체험존에 반영됩니다.</p>
    </template>
  </Dialog>
  <!-- e: 삭제 확인 Dialog -->

  <!-- s: 수정 확인 Dialog -->
  <Dialog :isOpen="modifyDialogState" :yes="'수정'" :cancel="'취소'" v-on:cancelDialog="modifyDialogState = false" v-on:yesDialog="confirmModify()">
    <template #body>
      <h3>수정 확인</h3>
      <p>선택한 상품를 수정하시겠습니까? <br/>변경된 내용은 "발행하기"를 진행해야 체험존에 반영됩니다.</p>
    </template>
  </Dialog>
  <!-- e: 수정 확인 Dialog -->
</template>

<script>
import Dialog from "@/components/dialog";


export default {
  name: "index",
  layout: {
    name: 'admin',
  },
  components: {
    Dialog
  },
  data() {
    return {
      pageMode: '',
      deleteDialogState: false,
      modifyDialogState: false,
      productPreviewModal: false,
    }
  },
  methods: {
    removeProduct(){
      this.deleteDialogState = true;
    },
    modifyProduct(){
      this.modifyDialogState = true;
    },
    confirmRemove(){
      this.deleteDialogState = false;
      this.toastSuccess('상품이 삭제되었습니다.');
      this.redirect('/subMenu/products');
    },
    confirmModify(){
      this.modifyDialogState = false;
      this.toastSuccess('상품이 수정되었습니다.');
      this.redirect('/subMenu/products');
    },
    openModal() {
      this.productPreviewModal = true;
      document.documentElement.style.overflow = 'hidden';
    },
    closeModal() {
      this.productPreviewModal = false;
      document.documentElement.style.overflow = '';
    },
  },
  created() {
  },
}
</script>

<style scoped>

</style>